(function($) {
  // Accordion like Collapse
  $(document).on('click', '[data-toggle="st-collapse"]', function(element) {
    var $this = $(this),
        parentClass = $this.data('parent'),
        parentContainer = $(parentClass),
        targetClass = $this.data('target'),
        targetContainer = $(targetClass),
        allTargets = targetContainer.siblings('.collapse');

    if(targetContainer.is(':visible')) {
      targetContainer.hide()
    } else {
      allTargets.hide()
      targetContainer.show()
    }
  })
})(jQuery);
